<template>
  <div class="_page" :style="'width:'+windowWidth+'px'">
    <div class="_head">
      <div class="_ht1">你好！欢迎来到本站 ！</div>
      <div
        style="display: flex; justify-content: center; align-items: center"
        @click="signout()"
      >
        <div class="_ht1">退出登录</div>
        <img
          src="../imgs/tuichu.png"
          alt=""
          style="width: 20px; height: 20px; margin-left: 8px"
        />
      </div>
    </div>
    <div class="_user">
      <div class="_u1">
        <img src="../imgs/logo.png" alt="" style="width: 50px; height: 50px" />
        <div style="margin-left: 10px; color: #041587">
          <p style="font-size: 18px">查询系统</p>
          <p style="font-size: 11px">Prospective Vision in China</p>
        </div>
      </div>
      <div class="_u2">
        <img src="../imgs/renwu.png" alt="" style="width: 40px; height: 40px" />
        <div style="font-size: 20px; margin-left: 10px">{{userInfo.name}}</div>
      </div>
      <div class="_u3">
        所属组：<span style="color: #0066ec">{{userInfo.keshi}}</span>&nbsp;&nbsp;
        科室：<span style="color: #0066ec">{{userInfo.keshis}}</span>
      </div>
      <div class="_u3">
        单位：<span style="color: #0066ec">{{userInfo.dw}}</span>
      </div>
    </div>
    
    <div class="_subroute">
      <router-view />
    </div>

    <div class="_bottom">
      <div class="_bt1">
        © 2022 Bestseller. All Rights Reserved 津ICP备12007886号 -5
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      windowWidth:'',
      userInfo:{}
    };
  },
  created(){
this.userInfo=JSON.parse(localStorage.getItem('userInfo'))
  },
  mounted(){
    this.windowWidth=document.documentElement.clientWidth
  },
   
  methods: {
    signout() {
      this.$router.push("/login");
    },
    
  },
};
</script>
<style scoped>
._page {
  /* width: 1920px; */
  /* height: 100vh; */
  background: #f7f6fa;
}
._head {
  width: 1920px;
  height: 35px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #0066ec;
}

._bottom {
  width: 1920px;
  height: 100px;
  background: #ffffff;
  /* position: absolute;
  bottom: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
}
._bt1 {
  width: 254px;
  font-size: 14px;
  font-weight: 400;
  color: #707070;
  text-align: center;
  line-height: 22px;
}
._ht1 {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
}

._user {
  width: 1920px;
  height: 100px;
  background: #ffffff;
  box-sizing: border-box;
  padding-left: 154px;
  display: flex;
  align-items: center;
}
._subroute {
  width: 1920px;
  /* height: 529px; */
  /* background: yellow; */
  display: flex;
  justify-content: center;
  padding: 20px 0;
  box-sizing: border-box;
}
._u1 {
  display: flex;
  align-items: center;
}
._u2 {
  display: flex;
  align-items: center;
  margin-left: 75px;
}
._u3 {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  margin-left: 50px;
}

</style>