<template>
  <div class="page">
    <img src="../imgs/denlu.png" alt="" style="width: 50%; height: 100%" />
    <div class="_rightBox">
        <loginframe v-if="frameStatus" v-on:status='status'></loginframe>
        <modifyframe v-else v-on:status='status'></modifyframe>
    </div>
  </div>
</template>
<script>
import loginframe from "../components/loginbox.vue";
import modifyframe from "../components/modifybox.vue"
export default {
  components: {
    loginframe,//登录框
    modifyframe, //修改框
  },
  data() {
    return {
        frameStatus:true
    };
  },
  methods:{
    status(frameStatus){
        this.frameStatus=frameStatus
    }
  }
};
</script>
<style>
.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
._rightBox {
  width: 50%;
  height: 100vh;
  background: #ffffff;
  /* background: aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>