import request from "@/utils/axios";
//获取系统消息
export function system(data) {
    return request("POST","/api/index/system",data)
}
//登录
export function login(data) {
    return request("POST","/api/index/login",data)
}
//修改密码
export function edit(data) {
    return request("POST","/api/index/edit",data)
}
//获取轮播图
export function getswiper(data) {
    return request("POST","/api/index/images",data)
}
//获取公告
export function getmsg(data) {
    return request("POST","/api/index/getmsg",data)
}
//DRG入组查询(查询相似)
export function gettitle(data) {
    return request("POST","/api/index/gettitle",data)
}
//DRG获取列表
export function gettitles(data) {
    return request("POST","/api/index/gettitles",data)
}
//获取详情
export function getinfo(data) {
    return request("POST","/api/index/getinfo",data)
}
//MCC入组查询(查询相似)
export function getlist(data) {
    return request("POST","/api/index/getlist",data)
}
//MCC获取列表
export function getlists(data) {
    return request("POST","/api/index/getlists",data)
}
//MCC获取表
export function getinfos(data) {
    return request("POST","/api/index/getinfos",data)
}
//医生结余
export function getyi(data) {
    return request("POST","/api/index/getyi",data)
}
//医生入组编码
export function getdrgs(data) {
    return request("POST","/api/index/getdrgs",data)
}
//医生数据明细
export function getshuju(data) {
    return request("POST","/api/index/getshuju",data)
}
//医生支付统计
export function getgroup(data) {
    return request("POST","/api/index/getgroup",data)
}
//医生科室统计
export function getke(data) {
    return request("POST","/api/index/getke",data)
}
//医生科室统计汇总
export function getkes(data) {
    return request("POST","/api/index/getkes",data)
}
//院长详情
export function getyuan(data) {
    return request("POST","/api/index/getyuan",data)
}
//院长入组编码
export function getyuan2(data) {
    return request("POST","/api/index/getyuan2",data)
}
//院长医生查询
export function getyuan1(data) {
    return request("POST","/api/index/getyuan1",data)
}
//全年数据
export function getyear(data) {
    return request("POST","/api/index/getyear",data)
}
//全年数据详情
export function getyears(data) {
    return request("POST","/api/index/getyears",data)
}
//绩效统计
export function getke1(data) {
    return request("POST","/api/index/getke1",data)
}
// 精准查询
export function cha(data) {
    return request("POST","/api/index/cha",data)
}
//精准查询后续列表
export function getccc(data) {
    return request("POST","/api/index/getccc",data)
}
//主任
//结余
export function getyis(data) {
    return request("POST","/api/index/getyis",data)
}
//编码
export function getdrgss(data) {
    return request("POST","/api/index/getdrgss",data)
}
//支付






