<template>
  <div class="_box">
    <div @click="modify" class="_fanhui">
      <img
        src="../imgs/fanhui.png"
        alt=""
        style="width: 6px; height: 11px; margin-right: 10px"
      />
      <div class="_t4">返回</div>
    </div>
    <div class="_t1" style="margin-bottom: 64px">修改密码</div>
    <div class="_inputBox">
      <p class="_t2">手机号</p>
      <input
        type="text"
        v-model="phone"
        class="_input"
        placeholder="请输入手机号"
      />
    </div>
    <div class="_inputBox" style="margin-top: 26px">
      <p class="_t2">旧密码</p>
      <input
        type="text"
        v-model="oldPassword"
        class="_input"
        placeholder="请输入旧密码"
      />
    </div>
    <div class="_inputBox" style="margin-top: 26px">
      <p class="_t2">新密码</p>
      <input
        type="text"
        v-model="newPassword"
        class="_input"
        placeholder="请输入密码"
      />
    </div>
    <div class="_inputBox" style="margin-top: 26px">
      <p class="_t2">确认新密码</p>
      <input
        type="text"
        v-model="againNewPassword"
        class="_input"
        placeholder="请输入密码"
      />
    </div>
    <div style="width: 550px; margin-top: 57px">
      <button class="_btn" @click="edit()">立即修改</button>
    </div>
  </div>
</template>
<script>
import { edit } from "@/api/request";
export default {
  data() {
    return {
      frameStatus: true,
      phone: "",
      oldPassword: "",
      newPassword: "",
      againNewPassword: "",
    };
  },
  methods: {
    edit(){
      edit({
        tel:this.phone,
        password:this.newPassword,
        qr_password:this.againNewPassword,
        y_password:this.oldPassword,
        ime:'1'
      }).then(res=>{
        if(res.code==0){
          this.$notify({
            title: "警告",
            message: res.msg,
            type: "warning",
          });
        }else{
          this.$notify({
            title: "成功",
            message: "修改成功",
            type: "success",
          });
          this.modify()
        }
      })
    },
    modify() {
      this.$emit("status", this.frameStatus);
    },
  },
};
</script>
<style>
._t1 {
  font-size: 22px;
  font-weight: bold;
  color: #000000;
}
._t2 {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
._input {
  width: 530px;
  height: 70px;
  background: #f6f6f6;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 18px;
  margin-top: 10px;
  padding-left: 20px;
}
._read {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
._t3 {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
._btn {
  width: 550px;
  height: 76px;
  background: linear-gradient(180deg, #4baeff 0%, #008cff 100%);
  border-radius: 4px;
  border: none;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}
._xiu {
  width: 550px;
  margin-top: 30px;
  text-align: right;
  font-size: 18px;
  font-weight: 400;
  color: #008cff;
}
._box {
  display: flex;
  align-items: center;
  flex-direction: column;
}
._fanhui {
  width: 550px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30rpx;
}
._t4 {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
</style>