<template>
  <div class="_box">
    <img src="../imgs/logo.png" alt="" class="_logo" />
    <div class="_t1" style="margin-top: 12px; margin-bottom: 64px">
      查询系统
    </div>
    <div class="_inputBox">
      <p class="_t2">手机号</p>
      <input
        type="text"
        v-model="phone"
        class="_input"
        placeholder="请输入手机号"
      />
    </div>
    <div class="_inputBox" style="margin-top: 26px">
      <p class="_t2">密码</p>
      <input
        type="text"
        v-model="password"
        class="_input"
        placeholder="请输入密码"
      />
    </div>
    <div style="width: 550px; margin-top: 57px">
      <div class="_read">
        <img src="../imgs/awf.png" alt="" style="width: 14px; height: 14px" />
        <div class="_t3" style="margin-left: 4px">
          登录即同意<span style="color: #008cff">《用户协议及隐私政策》</span>
        </div>
      </div>
      <button class="_btn" @click="signIn">登录</button>
      <div class="_xiu" @click="modify">修改密码</div>
    </div>
  </div>
</template>
<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { login } from "@/api/request";
export default {
  data() {
    return {
      frameStatus: true,
      phone: "",
      password: "",
      ime:""
    };
  },
  created() {
    this.getvisitorId()
  },
  methods: {
     // 获取设备或浏览器唯一标识
    async getvisitorId() {
      const fp = await FingerprintJS.load()
      const result = await fp.get()
      const visitorId = result.visitorId
      console.log('唯一标识', visitorId)
      this.ime=visitorId
    },
    modify() {
      this.frameStatus = false;
      this.$emit("status", this.frameStatus);
    },
    signIn() {
      let data = {
        tel: this.phone,
        password: this.password,
        ime: this.ime,
        type:2
      };
      login(data).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$notify({
            title: "警告",
            message: res.msg,
            type: "warning",
          });
        } else {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          localStorage.setItem("uid", res.data.id);
          this.$notify({
            title: "成功",
            message: "登录成功",
            type: "success",
          });
          setTimeout(()=>{this.$router.push("/")},1000)
        }
      });
    },
  },
};
</script>
<style>
._logo {
  width: 98px;
  height: 98px;
  background: #ffffff;
  border-radius: 25px;
  border: 1px solid #707070;
}
._t1 {
  font-size: 22px;
  font-weight: 400;
  color: #041587;
}
._t2 {
  font-size: 18px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
._input {
  width: 530px;
  height: 70px;
  background: #f6f6f6;
  border-radius: 4px;
  border: none;
  outline: none;
  font-size: 18px;
  margin-top: 10px;
  padding-left: 20px;
}
._read {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
._t3 {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
._btn {
  width: 550px;
  height: 76px;
  background: linear-gradient(180deg, #4baeff 0%, #008cff 100%);
  border-radius: 4px;
  border: none;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}
._xiu {
  width: 550px;
  margin-top: 30px;
  text-align: right;
  font-size: 18px;
  font-weight: 400;
  color: #008cff;
}
._box {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>