import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import layout from '../views/layout.vue'
// import { component } from 'vue/types/umd'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    name: 'layout',
    component: layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: '首页',
        component: () => import('../views/home.vue'),
        meta: {
          closable: false
        }
      },
      {
        path: '/DRGJoin',
        name: 'DRG入组查询',
        component: () => import('../views/DRGJoin.vue'),
        meta: {
          closable: true
        }
      },
      {
        path: '/DRGlist',
        name: 'DRG数据列表',
        component: () => import('../views/DRGlist.vue'),
        meta: {
          closable: true
        }
      },
      {
        path: '/DRGlistinfo',
        name: 'DRG数据详情',
        component: () => import('../views/DRGlistinfo.vue'),
        meta: {
          closable: true
        }
      },
      {
        path: '/MCCJoin',
        name: 'MCC查询',
        component: () => import('../views/MCCJoin.vue'),
        meta: {
          closable: true
        }
      },
      {
        path: '/MCClist',
        name: 'MCC数据列表',
        component: () => import('../views/MCClist.vue'),
        meta: {
          closable: true
        }
      },
      {
        path: '/MCClistinfo',
        name: 'MCC数据详情',
        component: () => import('../views/MCClistinfo.vue'),
        meta: {
          closable: true
        }
      },
      {
        path: '/doctor',
        name: '医生专属',
        component: () => import('../views/doctor.vue'),
        redirect: '/cashsurplus',
        // meta: {
        //   closable: true
        // },
        children: [
          {
            path: '/cashsurplus',
            name: '结余统计',
            component: () => import('../views/doctor/cashsurplus.vue'),
            meta: {
              closable: false
            }
          },
          {
            path: '/doctorCode',
            name: '入组编码',
            component: () => import('../views/doctor/doctorCode.vue'),
            meta: {
              closable: false
            }
          },
          {
            path: '/doctorDetails',
            name: '数据明细',
            component: () => import('../views/doctor/doctorDetails.vue'),
            meta: {
              closable: false
            }
          },
          {
            path: '/doctorPay',
            name: '支付统计',
            component: () => import('../views/doctor/doctorPay.vue'),
            meta: {
              closable: false
            }
          },
          {
            path: '/doctorDepartment',
            name: '科室统计',
            component: () => import('../views/doctor/doctorDepartment.vue'),
            meta: {
              closable: false
            }
          }
        ]
      },
      {
        path: '/dean',
        name: '院长管理',
        component: () => import('../views/dean.vue'),
        redirect: '/section',
        // meta: {
        //   closable: true
        // },
        children: [
          {
            path: '/section',
            name: '科室查询',
            component: () => import('../views/dean/section.vue'),
            meta: {
              closable: false
            }
          },
          {
            path: '/deanCode',
            name: '院长编码查询',
            component: () => import('../views/dean/deanCode.vue'),
            meta: {
              closable: false
            }
          },
          {
            path: '/deanDetails',
            name: '院长数据明细',
            component: () => import('../views/dean/deanDetails.vue'),
            meta: {
              closable: false
            }
          },
          {
            path: '/deanDoctor',
            name: '按医生查询',
            component: () => import('../views/dean/deanDoctor.vue'),
            meta: {
              closable: false
            }
          },
          {
            path: '/deanAll',
            name: '全年数据',
            component: () => import('../views/dean/deanAll.vue'),
            meta: {
              closable: false
            }
          },
          {
            path: '/allDetails',
            name: '全年数据详情',
            component: () => import('../views/dean/allDetails.vue'),
            meta: {
              closable: false
            }
          },
          {
            path: '/deanResult',
            name: '绩效统计',
            component: () => import('../views/dean/deanResult.vue'),
            meta: {
              closable: false
            }
          }
        ]
      },
      {
        path: '/accurate',
        name: '精准查询',
        component: () => import('../views/accurate.vue'),
        meta: {
          closable: true
        }
      },
      {
        path: '/accurateList',
        name: '精准查询列表',
        component: () => import('../views/accurate/accurateList.vue'),
        meta: {
          closable: true
        }
      },
      {
        path: '/accurateDetails',
        name: '精准查询详情',
        component: () => import('../views/accurate/accurateDetails.vue'),
        meta: {
          closable: true
        }
      },
      {
        path:'/director',
        name:'主任专属',
        component:()=> import('../views/director.vue'),
        redirect:'/cashsurpluss',
        children:[
          {
            path: '/cashsurpluss',
            name: '结余统计',
            component: () => import('../views/director/cashsurpluss.vue'),
            meta: {
              closable: false
            }
          },
          {
            path: '/directorDetails',
            name: '主任数据明细',
            component: () => import('../views/director/directorDetails.vue'),
            meta: {
              closable: false
            }
          },
          {
            path: '/directorCode',
            name: '主任入组编码',
            component: () => import('../views/director/directorCode.vue'),
            meta: {
              closable: false
            }
          },
          {
            path: '/directorPay',
            name: '主任支付统计',
            component: () => import('../views/director/directorPay.vue'),
            meta: {
              closable: false
            }
          },
          {
            path: '/directorDepartment',
            name: '主任支付统计',
            component: () => import('../views/director/directorDepartment.vue'),
            meta: {
              closable: false
            }
          },
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
//路由守卫
router.beforeEach((to, from, next) => {
  //判断当前本地缓存中是否存在token 有则跳转首页 没有则去登录页面
  var uid = localStorage.getItem('uid')
  if (uid) {
    next()
  } else {
    if (to.path == '/login') {
      next()
    } else {
      next('/login')
    }
  }

})

export default router
